var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import FlexContainer from "components/shared/layout/FlexContainer";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { style as styleConfig } from "config";
const menuAnimation = (top) => {
    if (!top)
        return null;
    return keyframes `
      from {
        opacity: 0.6;
        top: ${top + 5}px;
      }
      to {
        opacity: 1;
        top: ${top}px;
      }
    `;
};
const StyledMenu = styled(FlexContainer) `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  background-color: ${styleConfig.colors.secondary};
  padding: 1em;

  @media (${styleConfig.media.desktop}) {
    align-items: flex-start;
    position: absolute;
    ${({ left }) => `left: ${left}px;`}
    ${({ top }) => `top: ${top}px;`}
    animation: ${({ top }) => menuAnimation(top)} 0.08s ease-in;
  }
`;
export const MenuItem = styled.span `
  color: ${styleConfig.colors.primary};
  cursor: pointer;
`;
export default function Menu(props) {
    const { flexDirection = "column", alignItems = "flex-start", children, button } = props, rest = __rest(props, ["flexDirection", "alignItems", "children", "button"]);
    const ref = useRef(null);
    const buttonRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowMenu(!showMenu);
    };
    const closeMenu = () => setShowMenu(false);
    useEffect(() => {
        const handleClickOutside = (event) => {
            event.stopPropagation();
            const clickTarget = event.target;
            const { current: menuElement } = ref;
            if (menuElement && !menuElement.contains(clickTarget)) {
                setShowMenu(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, []);
    const getButtonCoords = (buttonEl) => {
        if (!buttonEl)
            return { left: null, top: null };
        return {
            left: buttonEl.offsetLeft - buttonEl.offsetWidth / 2,
            top: buttonEl.offsetTop + buttonEl.offsetHeight + 10,
        };
    };
    const { left, top } = getButtonCoords(buttonRef.current);
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { ref: buttonRef }, button(toggleMenu)),
        showMenu && (React.createElement(StyledMenu, Object.assign({}, rest, { left: left, top: top, ref: ref, flexDirection: flexDirection, alignItems: alignItems }), showMenu && children(closeMenu)))));
}
