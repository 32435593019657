import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { size } from "lodash-es";
import { selectUser } from "../../selectors/user";
import { style as styleConfig, routes, publicRoutes } from "../../config";
import Icon from "../shared/primitives/Icon";
import { H3 } from "../shared/primitives/Heading";
import FlexContainer from "../shared/layout/FlexContainer";
import FlexItem from "../shared/layout/FlexItem";
import Navigation from "./Navigation";
import { openMenuDisplayAction } from "slices/mobile";
import LogoForProduct from "components/shared/primitives/LogoForProduct";
const Root = styled.header `
  width: 100%;

  background-color: ${({ isLoggedIn }) => (isLoggedIn ? styleConfig.colors.lightGray : styleConfig.colors.white)};
  @media (${styleConfig.media.desktop}) {
    background-color: ${styleConfig.colors.white};
  }

  @media print {
    display: none;
  }
`;
const BurgerLink = styled.a `
  display: inline-block;
  padding: 0 0 0 1em;
`;
const BlockedMessage = styled(H3).attrs({ as: "div" }) ``;
export default function Header() {
    const displayMenu = useSelector((state) => state.mobile && state.mobile.displayMenu);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const handleBurgerClick = (event) => {
        event.preventDefault();
        dispatch(openMenuDisplayAction());
    };
    const isLoggedIn = !!size(user);
    const isOnPublicPage = publicRoutes.includes(pathname.replace(/\/+$/, ""));
    return (React.createElement(Root, { isLoggedIn: isLoggedIn },
        React.createElement(FlexContainer, { flexWrap: "wrap", alignItems: "center" },
            React.createElement(FlexItem, { column: 1 / 3, textAlign: "left", padding: ".5em 0 .5em 1em", desktop: { column: 1 / 5, padding: "1rem 0 1rem 3rem" } },
                React.createElement(Link, { to: routes.root() },
                    React.createElement(LogoForProduct, null))),
            React.createElement(FlexItem, { column: 2 / 3, padding: ".5em 1em .5em 0", desktop: { display: "none" }, textAlign: "right" },
                React.createElement(BurgerLink, { onClick: handleBurgerClick },
                    React.createElement(Icon, { icon: "burger", margin: "0", "aria-label": "Men\u00FCpunkte anzeigen" }))),
            React.createElement(FlexItem, { column: 1 / 1, padding: "0", display: displayMenu ? "block" : "none", desktop: {
                    display: "block",
                    column: 4 / 5,
                    padding: "1rem 3rem 1rem 0",
                    textAlign: "right",
                }, textAlign: "center" },
                React.createElement(Navigation, null)),
            !isOnPublicPage && user && user.blocked && (React.createElement(FlexItem, { column: 1 / 1, backgroundColor: styleConfig.colors.secondary, textAlign: "center", padding: "2em 0 0", desktop: { textAlign: "center", padding: "2em" } },
                React.createElement(BlockedMessage, { color: "primary" }, "Du kannst vor\u00FCbergehend keine Schichten belegen. Bitte melde Dich bei uns."))))));
}
