import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectCustomer } from "selectors/customer";
import { style as styleConfig } from "config";
import FlexContainer from "./shared/layout/FlexContainer";
import { H2, H3 } from "./shared/primitives/Heading";
import FlexItem from "./shared/layout/FlexItem";
import Paragraph from "./shared/primitives/Paragraph";
import { formatDayShort } from "./helpers/dateFormatting";
import { formatLocaleNumber, maxTwoDecimals } from "./helpers/numberFormatting";
const PaymentModelViewRoot = styled(FlexContainer) `
  margin: auto;
  margin-top: 2rem;
  flex-direction: column;
  width: 95%;
  min-height: 50vh;
  justify-content: flex-start;

  @media (${styleConfig.media.desktop}) {
    max-width: 1024px;
  }
`;
const PaymentModelViewItem = styled(FlexItem) `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (${styleConfig.media.desktop}) {
    max-width: 1024px;
  }
`;
function PaymentModelView(props) {
    const { customer } = props;
    const paymentModel = customer.paymentModel;
    return (React.createElement(PaymentModelViewRoot, null,
        React.createElement(H2, null, "Konditionen"),
        !paymentModel && React.createElement(Paragraph, null, "Es wurden noch keine Konditionen hinterlegt."),
        paymentModel && (React.createElement(FlexContainer, { flexDirection: "column", gap: "1rem", alignItems: "center", fullWidth: true },
            React.createElement(PaymentModelViewItem, null,
                React.createElement(H3, null, "Preis pro Minute"),
                `${formatLocaleNumber(paymentModel.pricePerMinute)} €`),
            !!paymentModel.fixedPricePerMonth && (React.createElement(PaymentModelViewItem, null,
                React.createElement(H3, null, "Preis pro Monat"),
                `${formatLocaleNumber(paymentModel.fixedPricePerMonth)} €`)),
            !!paymentModel.flatMinutesPerMonth && (React.createElement(PaymentModelViewItem, null,
                React.createElement(H3, null, "Minutenkontingent pro Monat"),
                `${formatLocaleNumber(maxTwoDecimals(paymentModel.flatMinutesPerMonth))} Min.`)),
            !!paymentModel.flatMinutesPerYear && (React.createElement(PaymentModelViewItem, null,
                React.createElement(H3, null, "Minutenkontingent pro Jahr"),
                `${formatLocaleNumber(maxTwoDecimals(paymentModel.flatMinutesPerYear))} Min.`)),
            !!paymentModel.flatMinutesPerYearStartDate && (React.createElement(PaymentModelViewItem, null,
                React.createElement(H3, null, "Startdatum Minutenkontingent pro Jahr"),
                formatDayShort(paymentModel.flatMinutesPerYearStartDate, { includeYear: true }))),
            (!!paymentModel.remainingFlatMinutes || paymentModel.remainingFlatMinutes == 0) && (React.createElement(PaymentModelViewItem, null,
                React.createElement(H3, null, "Verbleibendes Minutenkontingent"),
                `${formatLocaleNumber(maxTwoDecimals(paymentModel.remainingFlatMinutes))} Min.`))))));
}
export default function PaymentModel() {
    const customer = useSelector(selectCustomer);
    if (!customer)
        return null;
    return React.createElement(PaymentModelView, { customer: customer });
}
