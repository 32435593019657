import styled from "styled-components";
export const GridContainer = styled.div `
  display: grid;
  grid-template-rows: auto;
  grid-gap: 0.25em 1em;
  break-inside: avoid-page;
  width: 100%;
`;
export const GridItem = styled.div `
  grid-column: auto;
  grid-row: auto;
  min-width: max-content;
  break-inside: avoid-page;
`;
export const HeadingGridItem = styled(GridItem) `
  margin-bottom: 0.5em;
  break-inside: avoid-page;
`;
